/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import qs from 'qs'
import {
  Flex,
  Page,
  H1,
  Hr,
  // Button,
  // H4,
  Paper,
  Text,
  FeatherIcon,
  Box,
  Badge,
} from '../../elements'
import { AnimationEntry, SEO, Badges, FiltersButtons } from '../../components'
import { useFilters } from '../../utils'

// const isFilteredOut = ({ paramsToFilter, queryParams, entry }) => {
//   // early return if there is no query param
//   if (!queryParams || Object.keys(queryParams).length === 0) {
//     return false
//   }

//   // we only want to filter query params that we know
//   const interestingParams = paramsToFilter.reduce((accu, current) => {
//     const currentQueryParamVal = queryParams && queryParams[current]
//     if (currentQueryParamVal) {
//       return {
//         ...accu,
//         [current]: currentQueryParamVal,
//       }
//     }
//     return accu
//   }, {})

//   return !Object.entries(interestingParams).filter(([param, value]) => {
//     if (param === 'category') {
//       return !!entry.categories.find(cat => cat === value)
//     }
//     return entry[param] === value
//   }).length
// }

const Animations = ({
  data: {
    animations: { nodes: animations },
    filters: { nodes: filtersArray },
  },
  location: { search: queryString = '', ...loc },
  // ...props
}) => {
  // const [filtersOpen, setFiltersOpen] = useState(false)
  // const [onlyShowFavorites, setOnlyShowFavorites] = useState(false)
  const {
    filterValues: { onlyFavorites, ...filterValues },
    setFilterValue,
    resetFilters,
    setFilterList,
    filters,
    filtersOpen,
    setFiltersOpen,
    filtersSelected,
  } = useFilters(
    'animations',
    {
      categories: null,
      days: null,
      onlyFavorites: false,
    },
    filtersArray,
  )
  const setFilterCategories = ({ ...params }) => {
    setFilterList({ property: 'categories', ...params })
  }
  const setFilterDays = ({ ...params }) => {
    setFilterList({ property: 'days', ...params })
  }

  useEffect(() => {
    // const paramsToFilter = ['category']
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true })

    // So that we can adapt the title of the page for sharing purposes
    // Does not work because canonical is still /animations/
    const onlyShowsTournaments = Boolean(
      queryParams &&
        queryParams.category &&
        queryParams.category === 'tournaments',
    )
    if (onlyShowsTournaments) {
      setFilterCategories({ val: 'tournaments', isSelected: false })
      navigate(loc.pathname, { replace: true })
    }
  }, [])

  const color = 'animations'

  return (
    <Page>
      {({ t }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `/animations/`,
              title: t('animations'),
              // description: true,
            }}
          />
          <Paper
            {...{
              bg: 'animations',
            }}
          >
            <Flex.container
              {...{
                alignItems: 'center',
                py: 0,
                css: {
                  cursor: 'pointer',
                },
                onClick: () => {
                  setFiltersOpen(!filtersOpen)
                },
              }}
            >
              <H1
                {...{
                  color: 'textAlt',
                  // px: [2, 2, 3],
                  // pl: 2,
                  css: {
                    flexGrow: 1,
                  },
                }}
              >
                {t('animations')}
              </H1>
              {filtersSelected.length > 0 ? (
                <Badge
                  {...{
                    borderRadius: 3,
                    mx: 2,
                    my: '1px',
                    p: null,
                    px: 2,
                    py: '2px',
                    // mr: 1,
                    color,
                    bg: 'textAlt',
                    fontSize: 1,
                  }}
                >
                  {filtersSelected.length > 1
                    ? filtersSelected.length
                    : t(filtersSelected[0]) || '1'}
                </Badge>
              ) : (
                !onlyFavorites && (
                  <Text {...{ color: 'textAlt', m: null, mx: 2 }}>
                    {t('filters')}
                  </Text>
                )
              )}
              {onlyFavorites && (
                <Flex
                  {...{
                    width: 3,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'textAlt',
                    border: 2,
                    borderColor: 'textAlt',
                    mr: 2,
                    css: {
                      cursor: 'pointer',
                      borderRadius: '100%',
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: color,
                      fill: 'textAlt',
                      // fill: color,
                      // size: 3,
                      size: 2,
                      // mr: 1,
                      // mb: 1,
                      css: {
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        padding: 1,
                      },
                    }}
                  />
                </Flex>
              )}
              <FeatherIcon
                {...{
                  icon: filtersOpen ? 'arrow-up' : 'arrow-down',
                  color: 'textAlt',
                  size: 4,
                }}
              />
            </Flex.container>

            <Box.container
              {...{
                color: 'textAlt',
                py: 0,
                maxHeight: filtersOpen ? '80vh' : '0px',
                css: {
                  transition: 'max-height .7s ease-out',
                  overflow: 'hidden',
                },
              }}
            >
              <Hr {...{ border: 0, borderBottom: 1, borderColor: 'textAlt' }} />
              <Badges
                {...{
                  mb: 2,
                  list: filters.categories
                    .sort((a, b) => (t(a) > t(b) ? 1 : -1))
                    .map(x => {
                      const isSelected =
                        filterValues.categories && filterValues.categories[x]

                      return {
                        val: t(x),
                        color: isSelected ? color : 'altText',
                        bg: isSelected ? 'textAlt' : 'inherit',
                        toggleSelect: () => {
                          setFilterCategories({ val: x, isSelected })
                        },
                      }
                    }),
                }}
              />
              <Badges
                {...{
                  mb: 2,
                  list: filters.days.map(x => {
                    const isSelected = filterValues.days && filterValues.days[x]

                    return {
                      val: t(x),
                      color: isSelected ? color : 'altText',
                      bg: isSelected ? 'textAlt' : 'inherit',
                      toggleSelect: () => {
                        setFilterDays({ val: x, isSelected })
                      },
                    }
                  }),
                }}
              />
              <FiltersButtons
                {...{
                  setFilterValue,
                  setFiltersOpen,
                  resetFilters,
                  color,
                  onlyFavorites,
                }}
              />
            </Box.container>
          </Paper>
          <Flex
            {...{
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              px: 0,
            }}
          >
            {animations.map((a, i) => {
              if (
                !a
                // || isFilteredOut({ paramsToFilter, queryParams, entry: a })
              )
                return null

              return (
                <AnimationEntry
                  {...{
                    key: a.id,
                    animation: a,
                    filterValues,
                    onlyFavorites,
                  }}
                />
              )
            })}
          </Flex>
        </React.Fragment>
      )}
    </Page>
  )
}

// ($category: String!, $queryName: String!)
export const pageQuery = graphql`
  query Animations($localizedFieldName: AnimationFieldsEnum) {
    animations: allAnimation(
      filter: { status: { eq: "published" } }
      sort: { fields: [start, end, $localizedFieldName] }
    ) {
      nodes {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        days
        start
        end
        complexSchedule {
          Start
          End
        }
        schedule
        # schedule {
        #   friday {
        #     times
        #   }
        #   saturday {
        #     times
        #   }
        #   sunday {
        #     times
        #   }
        #   inverse
        # }
        categories
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              url
              width
              height
            }
            large {
              url
              width
              height
            }
            full {
              url
              width
              height
            }
          }
        }
        boardgames {
          featuredImage {
            id
            url
            size
            thumbnails {
              small {
                url
                width
                height
              }
              large {
                url
                width
                height
              }
              full {
                url
                width
                height
              }
            }
          }
        }
      }
    }
    filters: allFilter(filter: { onType: { eq: "animation" } }) {
      nodes {
        name
        values
        minValue
        maxValue
      }
    }
  }
`

export default Animations
